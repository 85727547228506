.App {}

body {
  background-color: black;
  color: white;
}

.topBar {
  display: flex;
  min-height: 2em;
  font-size: 2em;
  margin: 0.5em;
}

.topBar .leftCorner {
  align-items: flex-start;
}

.topBar .rightCorner {
  margin-left: auto;
}

.icon {
  cursor: pointer;
}

input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px #111 inset !important;
}
